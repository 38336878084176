import { gql } from "@apollo/client/core";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { Stock, MutationUpdateStockArgs } from "@/graphql/types";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useValidation } from "vue3-form-validation";
import { formatDate, trim } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
import moment from "moment";

type UpdateStockData = {
  updateStock: Stock;
};
const UPDATE_STOCK = gql`
    mutation UpdateStock($input: UpdateStockInput!) {
        updateStock(input: $input) {
          ${STOCK_FIELDS}
        }
    }
`;

export const useUpdateStock = (callback: () => void) => {
  const { form, validateFields } = useValidation({
    id: {
      $value: "",
    },
    note: {
      $value: "",
      $rules: [(n: string) => !!n && n.length > 255 && t("stock.noteError")],
    },
    vat: {
      $value: 0,
    },
    alertAt: {
      $value: null,
      $rules: [() => checkDates() && ""],
    },
    expiredAt: {
      $value: null,
      $rules: [() => checkDates() && ""],
    },
    purchasePrice: {
      $value: 0,
      $rules: [(n: number) => n < 0 && ""],
    },
    inclTax: {
      $value: 0,
      $rules: [(n: number) => n < 0 && ""],
    },
    exclTax: {
      $value: 0,
      $rules: [(n: number) => n < 0 && ""],
    },
    quantity: {
      $value: 0,
      $rules: [(n: number) => n == null && ""],
    },
  });

  function checkDates() {
    if (form.alertAt.$value && form.expiredAt.$value) {
      return moment(form.alertAt.$value).isAfter(form.expiredAt.$value);
    }
    return false;
  }

  function openDialog(item: Stock) {
    form.id.$value = item.id;
    form.expiredAt.$value = item.expiredAt
      ? moment(item.expiredAt).toDate()
      : null;
    form.alertAt.$value = item.alertAt ? moment(item.alertAt).toDate() : null;
    form.vat.$value = item.vat;
    form.purchasePrice.$value = item.purchasePrice;
    form.exclTax.$value = item.exclTax;
    form.inclTax.$value = item.inclTax;
    form.note.$value = item.note;
    form.quantity.$value = item.quantity;
  }
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onDone } = useMutation<
    UpdateStockData,
    MutationUpdateStockArgs
  >(UPDATE_STOCK);
  onDone(({ data }) => {
    const success = !!data?.updateStock;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("update.title"),
      detail: t(`update.${success ? "success" : "failed"}`),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (success) callback();
  });
  function submitUpdate() {
    validateFields().then((input) => {
      trim(input);
      input.expiredAt = form.expiredAt.$value
        ? formatDate(form.expiredAt.$value)
        : null;
      input.alertAt = form.alertAt.$value
        ? formatDate(form.alertAt.$value)
        : null;
      Object.assign(input, { updatedAt: new Date() });
      void mutate({ input: { ...input, activityId: activeActivity.value.id } });
    });
  }
  return { submitUpdate, loading, form, openDialog };
};
